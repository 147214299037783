import React from "react";

const Footer = () => {
    return (
        <footer>
            <div className="container">
                <div className="row">
                    <div className="col-4 m-col-12 section">
                        <a href="" className="logo"></a>
                        <div className="info address">
                            Registered Office: Ground Floor, Plot No. 112,
                            Sector 44, Gurgaon Sector 45, Gurgaon, Gurgaon,
                            Haryana, India, 122003
                        </div>
                        <div className="info address">
                            Corporate Office : Awfis, 9th Floor, Unitech Cyber
                            Park, Sector 39, Gurgaon, Haryana - 122002
                        </div>
                        <div className="info address">
                            CIN: U74999HR2020PTC091133
                        </div>
                        <div className="info email">
                            <a
                                href="mailto:support@crego.io?subject=Communication with Crego"
                                className="link"
                            >
                                support@crego.io
                            </a>
                        </div>
                    </div>
                    <div className="col-4 m-col-12 section">
                        <strong className="heading">Products</strong>
                        <ul className="nav">
                            <li>
                                <a className="link">Pay Later</a>
                            </li>
                            <li>
                                <a className="link">Vendor Financing</a>
                            </li>
                            <li>
                                <a className="link">Receivable Financing</a>
                            </li>
                            <li>
                                <a className="link">Cash Advance</a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-4 m-col-12 section">
                        <strong className="heading">More</strong>
                        <ul className="nav">
                            <li>
                                <a href="" target="_blank" className="link">
                                    API Documentation
                                </a>
                            </li>
                            <li>
                                <a href="" target="_blank" className="link">
                                    Android SDK
                                </a>
                            </li>
                            <li>
                                <a
                                    href="/terms-and-conditions"
                                    className="link"
                                >
                                    Terms &amp; Conditions
                                </a>
                            </li>
                            <li>
                                <a href="/privacy-policy" className="link">
                                    Privacy Policy
                                </a>
                            </li>
                            <li>
                                <a
                                    href="/regulatory-and-compliance"
                                    className="link"
                                >
                                    Regulatory &amp; Compliance
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="copyright-info">
                    <div className="container container-sm text-center">
                        <p className="text">
                            {" "}
                            Copyright © {new Date().getFullYear()}. Crego
                            (Kiranovations Technology Pvt. Ltd.). All Rights
                            Reserved.{" "}
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
