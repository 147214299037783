import React from 'react'
import Footer from '../components/layouts/Footer'
import Header from '../components/layouts/Header'
import homeBg from '../assets/img/home-bg.png'
import apiScreenshot from '../assets/img/api-screenshot.png'
import 'remixicon/fonts/remixicon.css';
import '../assets/svgs/constellation.css'
import '../assets/svgs/constellation-light.css'


const Home = () => {
    return (
        <>
            <Header />
            <div className="home">
                <section className="banner home-banner">
                    <div className="overlay">
                        <div className="content">
                            <div className="container">
                                <div className="row">
                                    <div className="img col-6 m-col-12">
                                        <img src={homeBg} alt="" />
                                    </div>
                                    <div className="text col-6 m-col-12">
                                        <h1 className="heading">Lending Infrastructure for Distributed Supply of Capital</h1>
                                        <p>
                                            We provide supply chain demand along with necessary infrastructure for local lenders to deploy their capital
                                        </p>
                                        <br />
                                        <a href="mailto:hello@crego.tech" className="btn btn-accent"> Contact Us </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="wrapper">
                    <div className="container">
                        <div className="head">
                            <h3 className="heading">Ready to Use</h3>
                            <p className="text">
                                Demand, KYC Validation, Underwriting, Portfolio Management & Collection Tools
                            </p>
                        </div>
                        <div className="content">
                            <div className="row cards">
                                <div className="col-3 m-col-12">
                                    <div className="card red">
                                        <h3>KYC & Validation</h3>
                                        <p>Built-in CRM & KYC Validation with Rule Engine</p>
                                    </div>
                                </div>
                                <div className="col-3 m-col-12">
                                    <div className="card yellow">
                                        <h3>Underwriting Tools</h3>
                                        <p>Bureau, Banking & GST reporting & automated decisioning system for faster Underwriting</p>
                                    </div>
                                </div>
                                <div className="col-3 m-col-12">
                                    <div className="card green">
                                        <h3>Agreement Signing</h3>
                                        <p>Built in tools for document signing, eNACH with support for multiple vendors</p>
                                    </div>
                                </div>
                                <div className="col-3 m-col-12">
                                    <div className="card blue">
                                        <h3>API Driven</h3>
                                        <p>End to end API stack helps NBFCs automate their capital deployment</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="wrapper colored">
                    <div className="container">
                        <div className="content">
                            <div className="row">
                                <div className="col-4 m-col-12">
                                    <div>
                                        <h2 className="heading">
                                            Most Advanced Credit Operating System for <span className="text-accent">NBFCs & Banks</span>
                                        </h2>
                                        <p>
                                            Crego is a distributed ecosystem which has been created to bridge the ecosystem of enterprises, NBFCs and Banks through a reliable, high performance technology stack. Crego has developed a unique credit operating system that can be used for any kind of credit product. We offer an easy and quick way for banks and NBFCs to expand their product portfolio to cater to the needs of their clients.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-1 m-col-0"></div>
                                <div className="col-7 m-col-12">
                                    <div className="row">
                                        <div className="col-6 m-col-12">
                                            <i className="ri-2x text-accent  ri-rocket-fill"></i>
                                            <div>
                                                <strong className=""> No integration required, ready to start instantly </strong>
                                                <p>
                                                    Minimal workload at your end, Crego does all the work for you. Onboarding takes only 1 day.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-6 m-col-12">
                                            <i className="ri-2x text-accent ri-user-voice-fill"></i>
                                            <div>
                                                <strong className="">Access to Demands</strong>
                                                <p>Once NBFCs and Banks plug into our system, they can get access to demand on our platform without doing anything</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6 m-col-12">
                                            <i className="ri-2x text-accent ri-shield-star-fill"></i>
                                            <div>
                                                <strong className="">Meets all the compliance requirements</strong>
                                                <p>
                                                    One-click downloadable reports to meet all your compliance & accounting needs as per RBI guidelines
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-6 m-col-12">
                                            <i className="ri-2x text-accent ri-settings-fill"></i>
                                            <div>
                                                <strong className="">Manage your own Portfolio Control </strong>
                                                <p>
                                                    Track your portfolio granularly and set your own control parameters as per your business needs
                                                </p>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-6 m-col-12">
                                            <i className="ri-2x text-accent ri-timer-flash-fill"></i>
                                            <div>
                                                <strong className="">Automate Collections </strong>
                                                <p>
                                                    Our collection stack and reconciliation helps you take repayments without putting any efforts.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-6 m-col-12">
                                            <i className="ri-2x text-accent ri-auction-fill"></i>
                                            <div>
                                                <strong className="">Manage Post Disbursal Processes</strong>
                                                <p>We provide end-to-end tools to simplify your loan resolution process.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="wrapper">
                    <div className="container">
                        <div className="content">
                            <div className="row">
                                <div className="col-4 m-col-12">
                                    <div>
                                        <h2 className="heading">
                                            Our <span className="text-accent-dark">API Driven</span> automation helps businesses run 10x faster
                                        </h2>
                                        <p>
                                            Do you own a technology stack for your business? Our API Stack helps NBFCs and enterprises automate Credit flow and reduce manual intervention thus making operations seamless and smooth.
                                        </p>
                                        <br />
                                        <a target="_blank" href="" className="btn btn-accent-dark"> Read Documentation </a>
                                    </div>
                                </div>
                                <div className="col-8 m-col-12">
                                    <img src={apiScreenshot} alt="API Screenshot" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="wrapper colored text-center intro">
                    <div className="container">
                        <div className="head">
                            <h3 className="heading">Ready to become a Partner?</h3>
                            <p className="text">
                                Automate your business with our distributed Credit Operating System. Start now by writing an email to explore solutions for your business.
                            </p>
                            <br />
                            <a href="mailto:hello@crego.tech" className="btn btn-accent"> Write an E-Mail </a>
                        </div>
                        <div className="content"></div>
                    </div>
                </section>
            </div>
            <Footer />
        </>
    )
}

export default Home